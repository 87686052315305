<template>
  <div class="bg-white">
    <div
      class="max-w-7xl mx-auto ml-auto py-4 px-4 w-full sm:px-6 lg:pl-8 lg:py-4"
    >
      <div class="">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <p class="text-xl text-gray-500"></p>
        </div>
        <ol class="justify-between pb-4 flex flex-col">
          <li v-for="(events, year) in years" :key="year.name">
            <div class="">
              <h3 class="text-3xl text-bold text-indigo-800">
                {{ year.substring(1) }}
              </h3>
              <div
                class="
                  ml-4
                  md:ml-8
                  pt-4
                  pb-12
                  mb-4
                  border-l-4 border-indigo-800
                "
              >
                <ol class="ml-4 md:ml-8">
                  <li class="mt-8" v-for="event of events" :key="event.id">
                    <a
                      v-if="
                        event.link.startsWith('http') ||
                        event.link.endsWith('.pdf') ||
                        event.link.endsWith('.png')
                      "
                      :href="event.link"
                      class="
                        inline
                        text-black text-md
                        md:text-xl
                        border-b-2 border-black
                        cursor-pointer
                      "
                    >
                      {{ event.title }}
                    </a>
                    <router-link
                      v-else
                      class="
                        inline
                        text-black text-md
                        md:text-xl
                        border-b-2 border-black
                        cursor-pointer
                      "
                      :to="event.link"
                    >
                      {{ event.title }}
                    </router-link>
                  </li>
                </ol>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
  setup() {
    return {
      years: {
        _2021: [
          {
            title:
              "AI-Campus Guest Lecture: Dr. Haitao Pan - An Introduction to Modern Methods for Pediatric Oncology Trials",
            month: "April",
            link: "/seminarFliers/pan_07222021.pdf",
            date: 13,
          },
          {
            title:
              "AI-Campus Guest Lecture: Dr. Stefan Bekiranov - Development and Assessment of a Quantum Genomic Classifier",
            month: "April",
            link: "/seminarFliers/BekiranovFlyer.pdf",
            date: 13,
          },
          {
            title:
              "AI-Campus Teams Places 1st in EHR Covid-19 DREAM Challenge Question 2",
            month: "April",
            link: "/ehr_challenge.pdf",
            date: 13,
          },
        ],
        _2020: [
          {
            title: "AI-Campus Develops Kidney Tumor Segmentation Model",
            month: "April",
            link: "/kidney.pdf",
            date: 13,
          },
          {
            title:
              "ARKANSAS AI-CAMPUS CONTINUES WORK VIRTUALLY DURING COVID-19 PANDEMIC",
            month: "April",
            link: "https://armoneyandpolitics.com/arkansas-ai-campus-continues-work-virtually-during-covid-19-pandemic/",
            date: 13,
          },
        ],
        _2019: [
          {
            title:
              "Artificial Intelligence Campus Demonstration Held In Little Rock",
            month: "February",
            day: 20,
            link: "https://aralliance.org/artificial-intelligence-campus-demonstration-held-in-little-rock/",
          },
          {
            title:
              "RESEARCHERS PRESENT ARTIFICIAL INTELLIGENCE PROJECT IN LITTLE ROCK",
            month: "February",
            day: 18,
            link: "https://amppob.com/researchers-present-artificial-intelligence/",
          },
          {
            title: "Part 2: AI Projects Demonstations in Little Rock",
            month: "February",
            day: 15,
            link: "https://www.facebook.com/johnathan.reaves/videos/2385446874823181/",
          },
          {
            title: "Part 1: AI Projects Demonstations in Little Rock",
            month: "February",
            day: 15,
            link: "https://www.facebook.com/johnathan.reaves/videos/2385148401519695/",
          },
          {
            title: "ARTIFICIAL INTELLIGENCE PROJECTS TO BE SHOWCASED FRIDAY",
            month: "February",
            day: 14,
            link: "https://amppob.com/artificial-intelligence-showcased/",
          },
        ],
        _2018: [
          {
            title: "AI-Campus Registration >>",
            link: "/ai-campus_overview_2018.pdf",
            month: "October",
            date: 10,
          },
          {
            title:
              "Data_Science_Seminar: Dr. Fred Prior & DR. Linda Larson-Prior - Biomedical Informatics: Analytics for Precision Medicine. & Connectomics: Mapping the Human Brain in Health and Disease.",
            link: "/seminarFliers/Data_Science_Seminar_04-13-Fred.pdf",
            month: "October",
            date: 10,
          },
          {
            title:
              "Data_Science_Seminar: Dr. Lawrence Cornett - The Arkansas INBRE Program",
            link: "/seminarFliers/Data_Science_Seminar_04-24-Cornett.pdf",
            month: "October",
            date: 10,
          },
        ],
        _2017: [
          {
            title:
              "Bioinformatics Seminar: Neuroimaging: Understanding the diseased brain",
            tags: ["bioinformatics"],
            link: "/seminarFliers/Bioinformatics_Seminar_2017-09-14-Linda.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Statistical Methods for Compositional Data Analysis with Application in Metagenomics",
            tags: ["bioinformatics"],
            link: "/seminarFliers/Bioinformatics_Seminar_2017-10-20-Hongmei.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Pleiotropy in Evolution and Disease",
            tags: ["bioinformatics"],
            link: "/seminarFliers/Bioinformatics Seminar-Scott.pdf",
          },
        ],
        _2016: [
          {
            title:
              "Bioinformatics Seminar: Dynamic Tracking and Analysis of Massive Social and Biological Data Sets",
            tags: ["bioinformatics"],
            link: "/seminarFliers/Bioinformatics_Seminar_10-13-NSF Zhang.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Establishing the new Molecular Bioinformatics Core (mBIO)",
            link: "/seminarFliers/Bioinformatics_Seminar--February-12-2016--Daniel-Johnson.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: A Scalable and Adaptive Approach for NGS Analyses & Reverse Engineering the Protein Folding Code",
            link: "/seminarFliers/Bioinformatics_Seminar-January-29--Dr.-Ashby-and-Dr.-Walker.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Development of Bioinformatics Algorithms and their Applications in Toxicological Research",
            tags: ["bioinformatics"],
            link: "/seminarFliers/Bioinformatics_Seminar_10-13_HHong.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Genetic Analysis of Human Health Using Artificial Intelligence & No-Boundary Thinking (NBT) Development at URI",
            tags: ["bioinformatics"],
            link: "/seminarFliers/BioinformaticsSeminar_02-26a-Jason Joan.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Association Mapping and SNP Identification in Spinach Molecular Breeding",
            tags: ["bioinformatics"],
            link: "/seminarFliers/BioinformaticsSeminar_09-15-Shi.pdf",
          },
          {
            title:
              "Bioinformatics Seminar: Biomedical Informatics: Analytics for Precision Medicine & Connectomics: Mapping the Human Brain in Health and Disease",
            tags: ["bioinformatics"],
            link: "/seminarFliers/Bioinformatics_Seminar_09-16-Fred Linda.pdf",
          },
        ],
      },
    };
  },

  metaInfo: {
    title: "About Us",
  },
};
</script>
